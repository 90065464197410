import { IDashboardItem } from "../../core/types/code_service/IDashboardItem"
import useApp from "../../hooks/useApp"
import useUser from "../../hooks/useUser"
import { observer } from "mobx-react"
import { Box, Grid, MenuItem, Select, TextField, Tooltip } from "@mui/material"
import { isEndUserView } from "../../core/utils/main"
// import AnswerSection from "../AskCubie/AnswerSection"
import ClearIcon from "@mui/icons-material/Clear"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { FC, useState } from "react"
import { makeStyles } from "../../core/utils/theme"
import FeedbackControl from "../AskCubie/FeedbackControl"
import { post2api } from "../../core/services/code_service"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CopyToClipboard from "react-copy-to-clipboard"
import CodeIcon from '@mui/icons-material/Code';
import { success } from "../../core/services/alerts"

const useStyles = makeStyles()(() => ({

  gridItemContainer: {
    margin:'20px',
    overflow:'hidden',
    position:'relative',
    border:'1px solid black',
    width:'608px',
    // height:'430px',
    height:'608px',
    // minHeight:'430px',
    // maxHeight:'608px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between'
  },
  dashboardItemControls: {
    width:'100%',
    height:'26px',
    position:'relative',
    display:'flex',
    background:'rgba(0,0,0,0.05)',
    borderBottom:'1px solid grey',
  },
  dashboardItemControl: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height:'100%',
    cursor:'pointer',
    opacity:'30%',
    '&:hover': {
      opacity:'100%'
    }
  },
  answerContainer: {
    position:'relative',
    display:'flex',
    flexDirection:'column',
    width:'100%',
    height:'564px',
    overflow:'hidden',
    zIndex:'0',
  },
  answerWrapper: {
    overflowY:'auto',
    textOverflow:'ellipsis',
    position:'relative',
    scale:'67%',
    width:'150%',
    minHeight:'150%',
    // paddingY:'2px',
    left:'-150px',
    top:'-142px',
    zIndex: '1',
    paddingTop:'5px',
    flex: '1 0',
  },
  dashboardItemLowerControls: {
    flex:'0 0',
    background:'#eee',
    backgroundOpacity:'100%',
    height:'16px',
    width:'100%',
    padding:'0px 2px 0 2px',
    display:'flex',
    justifyContent:'space-between',
  },
}))

const timestr = (s: string) => {
  if (!s)
    return ''
  return s.slice(0,19).replace('T', '  ')
}

interface IPropsDashboardItem {
  item: IDashboardItem
  i: number
  numItems: number
  handlers: any
}

const DashboardItem: FC<IPropsDashboardItem> = observer(({ item, i, numItems, handlers }) => {
  const app = useApp()
  const user = useUser()
  const { classes } = useStyles()

  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [title, setTitle] = useState(item.answer?.title || '')

  const handleTitleEdit = () => {
    setIsEditingTitle(true)
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const handleTitleSubmit = () => {
    if (item.answer)
      item.answer.title = title
    post2api("/edit-dashboard-title", {cake_id: app.cakeId, query_id: item.query_id, title: title})
    setIsEditingTitle(false)
  }

  const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleTitleSubmit()
    }
  }

  return (
    <Grid item className={classes.gridItemContainer} key={item.query_id}>
      <Box className={classes.dashboardItemControls}>
          {(user.isInsider() || !isEndUserView() || app.cakeId == 'nhanes_fd') &&
            <Tooltip title="Remove from Dashboard">
              <ClearIcon
                className={classes.dashboardItemControl}
                onClick={() => handlers['delete'](item.query_id)}
              />
            </Tooltip>
          }
          <Box className="grow w-full overflow-clip">
            {isEditingTitle ? (
              <TextField
                value={title}
                onChange={handleTitleChange}
                onBlur={handleTitleSubmit}
                onKeyDown={handleTitleKeyDown}
                variant="standard"
                autoFocus
                style={{width:'100%'}}
                inputProps={{
                  style: { fontSize: 'inherit', padding: 0 }
                }}
              />
            ) : (
              <Box
                className="inline relative top-[1px] left-[4px] cursor-pointer grow maxWidth:'100%'"
                onClick={handleTitleEdit}
              >
                {title}
              </Box>
            )}
          </Box>
          <Box className="flex text-xs">
            {item.answer?.q && <Box className="flex flex-col content-center h-full">
              <CopyToClipboard text={item.answer?.q || ''} onCopy={()=>{success("Copied to clipboard")}}>
              <Tooltip title={item.answer?.q || ''}>
                <HelpOutlineIcon style={{height:'18px', margin:'auto', opacity:'50%'}} />
              </Tooltip>
              </CopyToClipboard>
            </Box>}
            {item.code && <Box className="flex flex-col content-center h-full">
              <CopyToClipboard text={JSON.stringify(item.code) || ''} onCopy={()=>{success("Copied to clipboard")}}>
              <Tooltip title={JSON.stringify(item.code) || ''}>
                <CodeIcon style={{height:'18px', margin:'auto', opacity:'50%'}} />
              </Tooltip>
              </CopyToClipboard>
            </Box>}
            <Select
              className="appearance-none bg-white"
              value={i as number + 1}
              onChange={(e) => handlers['reorder'](i + 1, e.target.value as number)}
            >
              {[...Array(numItems).keys()].map((_, index) => (
                <MenuItem className="bg-white hover:bg-blue-300 aria-selected:bg-blue-100 aria-selected:hover:bg-blue-300" key={index} value={index as number + 1}>{index + 1}</MenuItem>
              ))}
            </Select>
          </Box>

          {/* <Tooltip title="Re-run Analysis">
                    <ReplayIcon
                      className={classes.dashboardItemControl}
                      onClick={(e) => rerun(e, item.query_id)}
                    />
                  </Tooltip> */}

          <Tooltip title="Full screen">
            <Box className="height:100% grow-0">
              <OpenInFullIcon
                className={classes.dashboardItemControl}
                onClick={() => handlers['expand'](i)}
              />
            </Box>
          </Tooltip>
      </Box>
      <Box className="flex flex-col flex-grow">
        <Box className={classes.answerContainer}>
          <Box className={classes.answerWrapper}>
            {
              // item.answer?.chart_html ?  
              // <HTMLChart answerChartHtml={item.answer.chart_html} isDashboard={true}/>
              // :
              // <AnswerSection
              //   answer={item.answer}
              //   isDashboard={true} />
            }
          </Box>
        </Box>
      </Box>
      {item.saved_at &&
        <Box className={classes.dashboardItemLowerControls}>
          <Box className="text-black text-xs italic h-full flex flex-column justify-end" >
            Last run {timestr(item.saved_at)}
          </Box>
          {/* <Box  className="float-right cursor-pointer text-xs underline ">
                Re-run analysis
                </Box> */}
          <Box className="text-black text-xs italic h-full flex flex-column justify-end" >
            <FeedbackControl queryId={item.query_id} isDashboard={true} />
          </Box>

        </Box>
      }
    </Grid>
  )
})

export default DashboardItem
