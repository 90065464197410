// import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactModal from 'react-modal'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
// import { ThemeProvider, CacheProvider } from '@emotion/react'
// import createCache from '@emotion/cache'
import { CssBaseline } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AppContext } from './contexts/AppContext.tsx'
import { SettingsContext } from './contexts/SettingsContext.tsx'
import { UserContext } from './contexts/UserContext.tsx'
import mainRouter from './core/router/main.tsx'
import { AppStore } from './stores/AppStore.tsx'
import { SettingsStore } from './stores/SettingsStore.tsx'
import { UserStore } from './stores/UserStore.tsx'
import './styles/globals.css'
import theme from './core/utils/theme.ts'

ReactModal.setAppElement('#root')

const userStore = new UserStore()
const appStore = new AppStore()
const settingsStore = new SettingsStore(appStore)

// export const muiCache = createCache({
//   key: 'mui',
//   prepend: true,
// })

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
    // <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserContext.Provider value={userStore}>
          <AppContext.Provider value={appStore}>
            <SettingsContext.Provider value={settingsStore}>
              <RouterProvider router={mainRouter} />
              <ToastContainer theme="dark" hideProgressBar={true} />
            </SettingsContext.Provider>
          </AppContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    // </CacheProvider>
  // </React.StrictMode>
  ,
)
