import { observer } from "mobx-react"
import { Box } from "@mui/material"
import { FC, useState } from "react"


interface IEditableLine {
  line: string
  onFinish: (l:string)=>any
}

const EditableLine: FC<IEditableLine> = observer(({line, onFinish}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [newLine, setNewLine] = useState(line)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLine(e.target.value)
  }

  const handleDone = () => {
    setIsEditing(false)
    line = newLine
    onFinish(newLine)    
  }

  const handleKeyDown: React.KeyboardEventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' && (e.metaKey || e.ctrlKey)) || e.key == 'Escape') {
      handleDone()
    }
  }

  return (
          <Box className="grow w-full overflow-clip">
            {isEditing ? (
              <textarea
                value={newLine}
                onChange={handleChange}
                onBlur={handleDone}
                onKeyDown={handleKeyDown}
                autoFocus
                rows={newLine.split('\n').length}
                style={{width:'100%'}}
              />
            ) : (
              <Box
                className="inline relative top-[1px] left-[4px] cursor-pointer grow maxWidth:'100%'"
                onClick={handleEdit}
              >
                {newLine.split('\n').map((a) => {console.log("newLine=", newLine); console.log("a=", a); return <div>{a || <span>&nbsp;</span>}</div>})}
              </Box>
            )}
          </Box>
  )
})

export default EditableLine