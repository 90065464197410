export class QueryState {
    version: string = '0.2'
    qid: string = ''
    state: string = ''
    question: string = ''
    sources: {[name: string] : string[]} = {}
    assumptions: string[] = []
    clarification: string = ''
    plan: string[] = []
    code: string[] = []
    error: string = ''
    answer: string = ''
    html: string = ''
    revision: string = ''
    revisions: string[][] = []
  
    constructor(q: string = '') {
        this.state = 'new'
        this.question = q
    }
  }

