import theme, { makeStyles } from '../../core/utils/theme'
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { isEndUserView } from '../../core/utils/main'
import useSettings from '../../hooks/useSettings'
import { error } from '../../core/services/alerts'
import useApp from '../../hooks/useApp'
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useEffect } from 'react'
import { observer } from 'mobx-react'

const useStyles = makeStyles()((theme) => ({
  staticContainer: {
    backgroundColor: '#fff',
    height: 'fit-content',
    display: 'flex',
    justifyContent:
      'center',
    flexDirection: 'column',
    borderColor: 'black',
    borderWidth: 1,
    // minHeight: 325,
    maxHeight: 350,
    padding: '1rem',
  },
  innerContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // paddingLeft: 18,
    // paddingRight: 18,
    fontSize: 12,
    overflowY: 'auto',
  },
  questionInner: {
    display:'flex',
    justifyContent:'space-around',
    marginTop: '1rem',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: '2rem',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '1.5rem',
      marginTop: 0,
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '1rem',
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      gap: '1rem',
      marginTop: 0,
    },
  },
  question: {
    display: 'inline-block',
    maxWidth:'300px',
    height: '70px',
    overflow:'hidden',
    textOverflow:'ellipsis',
    cursor: 'pointer',
    // border: `1px solid ${theme.palette.text.disabled}`,
    fontWeight: '400',
    padding: '4px',
    color: '#119',
    '&:hover': {
      background: '#bbf',
      color: 'black',
      // border: `1px solid ${theme.palette.text.secondary}`,
    },

    '& > p': {
      margin: 0,
      textAlign: 'center',
      display: '-webkit-box',
      WebkitLineClamp:'3',
      WebkitBoxOrient:'vertical',
      // '-webkit-line-clamp': '3',
      // '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
  },
}))

export const SampleQuestions = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  const app = useApp()

  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isNotHugeScreen = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    // if (!settings.sampleQuestions)
    //   settings.loadSampleQuestions(app.cakeId)
    toggleNav()
  }, [])

  
  const handleOnClick = (question: string) => {
    if (app.isThinking) {
      error('Please wait until the previous question is answered or canceled.')
      return
    }
    if (app.isInitializing) {
      error('Please wait until tables are loaded')
      return
    }
    app.resetQuestion()
    app.setInput(question)
    toggleNav()
    app.ask()
  }

  const toggleNav = () => {
    const menu = document.getElementById('sampleQs')
    if (menu) menu.classList.toggle('-translate-x-full')
  }

  if (settings.sampleQuestions?.length == 0)
    return null
  if (isNotHugeScreen)
    return (
      <>
        <Tooltip title="Sample Questions" placement='left'>
          <QuizTwoToneIcon className="cursor-pointer z-[100] xl:hidden lg:hidden md:visible sm:visible" onClick={toggleNav} />
        </Tooltip>
        <div
          id="sampleQs"
          className="fixed -right-[240px] top-0 z-40 h-screen w-[240px] -translate-x-full overflow-y-auto bg-white p-4 transition-transform"
          aria-labelledby="drawer-label"
        >
          <KeyboardArrowRightIcon className="cursor-pointer absolute top-1 left-1" onClick={toggleNav} />
          <ul className="flex flex-col items-center gap-3">
            <Tooltip title="Sample Questions" placement='left'>
              <QuizTwoToneIcon className="cursor-pointer z-[100] xl:hidden lg:hidden md:visible sm:visible" onClick={toggleNav} />
            </Tooltip>
            <Typography textAlign={'center'} variant='h6' fontWeight={'bold'}>Sample Questions</Typography>
            <Box className={classes.questionInner} sx={isEndUserView() ? { display: 'flex',  alignItems:'center', flexDirection: 'column', gap: '1rem !important' } : { display: 'grid' }}>
        {settings.sampleQuestions?.length > 0 &&
          settings.sampleQuestions.map((item: { question: string }, i) => {

            // determine if the tooltip needs to be shown if the question length is greater than 101.
            // const showTooltip = item.question.length > 101;
            // disableHoverListener={!showTooltip} // PUT this back in tooltip to set the conditional rendering
            
            return (
              <Tooltip title={<p className='text-base'>{item.question}</p>}  arrow key={i} placement="left">
                <Box key={i} className={classes.question} onClick={() => handleOnClick(item.question)}>
                  <p className='text-base'>{item.question}</p>
                </Box>
              </Tooltip>
            )
          })}
      </Box>
          </ul>
        </div>
      </>
    )
  else
    return (
      <>
      <Box className="overflow-auto">
        <Box className={classes.questionInner} sx={isEndUserView() ? { display: 'flex',  alignItems:'center', gap: '1rem !important' } : { display: 'grid' }}>
          {settings.sampleQuestions?.length > 0 &&
            settings.sampleQuestions.map((item: { question: string }, i) => {
              // determine if the tooltip needs to be shown if the question length is greater than 101.
              // const showTooltip = item.question.length > 101;
              // disableHoverListener={!showTooltip} // PUT this back in tooltip to set the conditional rendering
              return (
                <Tooltip title={<p className='text-base'>{item.question}</p>}  arrow key={i} placement="left">
                  <Box key={i} className={classes.question} id={`${i==1 ? 'runSampleQuestion' : ''}`} onClick={() => handleOnClick(item.question)}>
                    <p className='text-sm italic '>{item.question}</p>
                  </Box>
                </Tooltip>
              )
            })}
        </Box>
      </Box>
      </>
    )
})
