import { makeAutoObservable } from 'mobx'
import { error, success } from '../core/services/alerts'
import { startSSE, stopSSE } from '../core/services/messages'
import { post2api, fetchDashboardItems, fetchAnswer, fetchSources, flagAnswer, postToDashboard, postToRunDashboardItem, postDeleteDashboardItem } from '../core/services/code_service'
import { IDataObject } from '../core/types/code_service/IDataObject'
import { getCakeIdFromUrl, getVersionFromUrl, randomString } from '../core/utils/main'
import { postModel } from '../core/services/source_service'
import { IDashboardItem } from '../core/types/code_service/IDashboardItem'
import { LOGIN_URL } from '../core/config/main'
import { IDatacake } from '../core/types/source_service/IDatacake'
import { IAnswerResponse } from '../core/types/code_service/IAnswerResponse'
import { QueryState } from './Query'

export class AppStore {

  public version: string = '0.2'
  public setVersion(v: string) {
    this.version = v
  }

  // ### Cubie State ##################################################################################
  public cakeId: string
  public setCakeId(cakeId: string) {
    this.cakeId = cakeId
  }

  public cakeName: string = ''
  public setCakeName(cakeName: string) {
    this.cakeName = cakeName
  }
  
  public coreDataObjects: IDataObject[] = []
  public setCoreDataObjects(sources: IDataObject[]) {
    this.coreDataObjects = sources
  }

  public sampleQuestionVisible: boolean = true
  public setSampleQuestionVisible(visible: boolean) {
    this.sampleQuestionVisible = visible
    this.prepareForQuestion(false)
  }

  private eventSource: EventSource | null = null

  public sessionId: string | null = null
  protected setSessionId(sessionId: string) {
    this.sessionId = sessionId
    console.log("Session ID", this.sessionId)
  }

  public traceId: string | null = null
  protected setTraceId(traceId: string) {
    this.traceId = traceId
    console.log("Trace ID", this.traceId)
  }

  public isInitializing: boolean = false
  public setIsInitializing(value:boolean) {
    this.isInitializing = value
  }

  public async updateSources(isEndUserView?: boolean) {
    if (!this.cakeId) {
      console.log("No cakeId, so no call to /initialize")
      return
    }
    if (this.isInitializing) {
      console.log("isInitializing true, so no new call to /initialize")
      return
    }
    try {
      this.setCoreDataObjects([])

      this.setIsInitializing(true)
      const response = await fetchSources(this.cakeId, isEndUserView)
      const sessionId = response.data.session_id

      if (sessionId === null) {
        error('session_id not initialized, please try loading the page again')
        return;
      }

      this.setSessionId(sessionId)

      const originalSources = response.data.core || []
      if (originalSources.length === 0) {
        error('tables didn’t load, please wait a few minutes and try again.')
        return;
      }
      this.setCoreDataObjects(originalSources)
    } catch (e: any) {
      if (e.response.status === 401 || e.response.status === 403) {
        
        this.setShowLoginModal(true)
        // return
      } else {
        this.handleError(e)
      }
    } finally {
      this.setIsInitializing(false)
    }
  }
  
  public resetCubie() {
    this.setCoreDataObjects([])
    this.setCakeId('')
    this.setCakeName('')
    this.sessionId = null
    this.queryState = new QueryState()
    this.setInput('')
    this.thoughtsText = ''
    this.setAnswer(null)
    this.dashboardItems = []
    this.chatHistory = []
  }

  public activateCake(cake: IDatacake) {
    this.resetCubie()
    this.setCakeId(cake.cake_id || '')
    this.updateSources()
    this.cakeName = cake.name || ''
    this.loadDashboardItems(this.cakeId)
  }

  // ### Question State ##################################################################################
  
  public queryState: QueryState = new QueryState()
  
  public input: string = ''
  public setInput(value: string) {
    this.input = value
  }
  
  public isThinking: boolean = false
  public setIsThinking(value:boolean) {
    this.isThinking = value
  }

  public thoughtsText: string = ''
  public _thoughtSource: string | null = null
  public answer: IAnswerResponse | null = null
  public setAnswer(ans: IAnswerResponse | null) {
    this.answer = ans
  }
  public cancelRequest: AbortController | null = null
  private _chatHistory: string[] = []
  public _collapsed: boolean = true

  public isThoughtsOpenedInNewWindow: boolean = false

  public isNegativeFeedbackLeft: boolean = false
  public isPositiveFeedbackLeft: boolean = false

  set chatHistory(qa: string[]) {
    const maxLength = 10
    this._chatHistory = this._chatHistory.concat(qa)
    this._chatHistory.splice(0, this._chatHistory.length - maxLength)
  }
  get chatHistory(): string[] {
    return this._chatHistory
  }
  
  public async ask() {
    this.setSampleQuestionVisible(false)
    this.prepareForQuestion(true)
    this.queryState.question = this.input
    const sseChannel = randomString()

    this.eventSource = startSSE(sseChannel, this.handleSSE.bind(this))

    let response = null

    this.cancelRequest = new AbortController()

    try {
      response = await fetchAnswer(
        this.queryState,
        [],
        sseChannel,
        this.sessionId,
        this.cakeId,
        this.cancelRequest.signal,
      )
    } catch (e: any) {
      this.setIsThinking(false)
      this._collapsed = false

      stopSSE(this.eventSource)

      this.handleError(e)
      return
    }

    stopSSE(this.eventSource)
    this.eventSource = null

    this.setIsThinking(false)
    this._collapsed = false

    this.handleQueryResponse(response)
  }

  handleQueryResponse(response:any) {
    if (response.status == 'ok') {
      console.log('Query completed', response.data)
      for (var k of Object.keys(response.data)) {
        this.queryState[k as keyof Object] = response.data[k as keyof Object]
      }
      this.queryState = response.data

      this.chatHistory = []
      this.cancelRequest = null
      this.traceId = response.trace
    } else if (response.status == 'error') {
      this.cancelRequest = null
      this.queryState = new QueryState()
      this.error = "I'm overwhelmed! Try reloading...."
      console.log("AppStore.tsx line 204 response.status == error")
    }
  }

  // public async sendQueryResponse() {
  //   params = {
  //     '': this.queryState,
  //     ''
  //   }
  //   const result = await post2api("/0.2/query", this.queryState)
  //   console.log("RESULT RESULT")
  //   console.log(result)
  // }


  public async editVisualization(revision: string) {
    const result = await post2api("/v1.0/edit-visualization", {cake_id: this.cakeId, session_id:this.sessionId, query_id: this.queryState.qid, chat_history:[], q: this.queryState.question, revision:revision})
    if (this.answer)
      this.answer.chart_html = result.data
  }

  public cancelRequestAction() {
    if (!this.cancelRequest) return
    stopSSE(this.eventSource as EventSource)

    this.cancelRequest.abort()
    this.cancelRequest = null
    this.eventSource = null
    this._collapsed = false
    this.setSampleQuestionVisible(true)
    this.prepareForQuestion(false)
  }

  protected handleSSE(event: MessageEvent) {
    const thought = JSON.parse(event['data'])
    this.thoughtsText += thought['text']
    // this.thoughtsText = this.thoughtsText.replace(/`(\S*)`/g, "<code>$1</code>")
    // this.thoughtsText = this.thoughtsText.replace("<br /><br /><br />", "<br /><br />")
    // this.thoughtsText = this.thoughtsText.replace("<br /><code><br />", "<code><br />")
    // this.thoughtsText = this.thoughtsText.replace(/\*\*([A-Za-z0-9_]*?)\*\*/g, "<b>$1</b>")
    // this.thoughtsText = this.thoughtsText.replace(/\*\*([A-Za-z0-9_]*?)\*\*/g, "<code>$1</code>")
  }

  public resetQuestion() {
    this.prepareForQuestion(false)
  }
  
  public prepareForQuestion(isThinking: boolean) {
    // this.queryState = this.input
    this.thoughtsText = ''
    this._thoughtSource = null
    this.setAnswer(null)
    this.error = ''
    this.isThinking = isThinking
    this.isNegativeFeedbackLeft = false
    this.isPositiveFeedbackLeft = false
  }

  // public toggleThoughtsVisible() {
  //   this.isThoughtsVisible = !this.isThoughtsVisible
  // }

  public async flagAnswer(queryId: string, value: number, feedback?: string, isDashboard: boolean = false) {
    let response = null
    try {
      const sessionId = isDashboard ? 'dashboard' : this.sessionId || ''
      const question = isDashboard ? '' : this.queryState.question
      const chatHistory = isDashboard ? [] : this.chatHistory
      response = await flagAnswer(this.cakeId, queryId, sessionId, value, feedback, question, chatHistory)
      if (response.status !== 'ok') {
        return
      }

      this.handleSuccess('Your feedback has been recorded. Thank you!')
    } catch (e) {
      this.handleError(e)
      return
    }
  }

  public setIsNegativeFeedbackLeft(value: boolean) {
    this.isNegativeFeedbackLeft = value
  }
  public setIsPositiveFeedbackLeft(value: boolean) {
    this.isPositiveFeedbackLeft = value
  }
  
  // ### Dashboard State ##################################################################################
  public isDashboardLoading = false
  public setIsDashboardLoading(value: boolean) {
    this.isDashboardLoading = value
  }
  
  public dashboardItems: IDashboardItem[] = []
  public setDashboardItems(value: IDashboardItem[]) {
    this.dashboardItems = value
  }
  public async saveDashboardOrder() {
    const dashboardOrder = this.dashboardItems.map((item) => item.query_id)
    await post2api('/dashboard-order', {cake_id: this.cakeId, order: dashboardOrder})
  }
  public tempDashboardItems: IDashboardItem[] = []
  public addToTempDashboardItems(value: IDashboardItem) {
    this.tempDashboardItems.push(value)
  }

  public async saveToDashboard(cakeId: string, queryId: string, element: HTMLElement) {
    element.innerText = "Saving..."
    const result = await postToDashboard(cakeId, queryId)
    if (result) {
      console.log(result)
      element.innerText = "Saved"
      setTimeout(()=>{
        this.loadDashboardItems(this.cakeId)
      }, 300)
      
    } else {
      console.log("Save to Dashboard failed", result)
    }
  }

  public async runAllDashboardItems(cakeId: string, element: HTMLElement) {
    let awaitingNum = this.dashboardItems.length
    this.dashboardItems.map(async (d: IDashboardItem) => {
      console.log("rerunning item...")
      const r = await postToRunDashboardItem(cakeId, d.query_id)
      console.log(r)
      console.log('done', element)
      awaitingNum -= 1
      if (awaitingNum <= 0)
        await this.loadDashboardItems(cakeId)
    })
  }

  public async runDashboardItem(cakeId: string, queryId: string, element: HTMLElement) {
    element.innerText = "Running..."
    const onclick_func = element.onclick
    element.onclick = null
    const result = await postToRunDashboardItem(cakeId, queryId)
    if (result.status == 'ok') {
      element.innerText = "Done"
      setTimeout(()=>{
        element.innerText = "Re-run analysis"
      }, 3000)
    } else {
      element.onclick = onclick_func
      element.innerText = "Run failed... Retry?"
    }
  }

  public async deleteDashboardItem(cakeId: string, queryId: string) {
    this.setIsDashboardLoading(true)
    const index = this.dashboardItems.map((x) => {return x.query_id}).indexOf(queryId)
    this.setDashboardItems(this.dashboardItems.slice(0,index).concat(this.dashboardItems.slice(index+1)))
    const result = await postDeleteDashboardItem(cakeId, queryId)
    if (result.status='ok')
      return true
  }

  public async loadDashboardItems(cakeId: string) {
    let response = null
    try {
      console.log('Loading dashboard items')
      this.setIsDashboardLoading(true)
      response = await fetchDashboardItems(cakeId)
      this.setIsDashboardLoading(false)
    } catch (e: any) {
      console.log(e)
      this.handleError(e)
      this.setIsDashboardLoading(false)
      return
    }
    console.log("Done loading dashboard items", response)
    if (response.status == 'ok') {
      response.data.forEach((x) => {
        if (x.answer?.title && (x.answer.title[0] == '"') && (x.answer.title[x.answer.title.length-1] == '"'))
          x.answer.title = x.answer.title.slice(1,x.answer.title.length-1)
      })
      this.setDashboardItems(response.data)
    } else {
      console.log("Error loading dashboard items", response.message)
    }
  }

  // ### Constructor ##################################################################################

  constructor() {
    makeAutoObservable(this)
    this.setVersion(getVersionFromUrl())
    this.cakeId = getCakeIdFromUrl()
  }

  // ### Other ##################################################################################

  public error: string = ''
  public showLoginModal = false
  // public isWelcomeModalOpen = true

  // @todo: Rework it: move the modal state to AppStore instead.
  // public modalCloseHandler: Function | null = null

  // // isWelcomeModal initiated for CreateDataCake event
  // public isWelcomeTriggedForCreateDataCake: boolean = false;


  public removeOpenModalParam() {
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  public setShowLoginModal(show: boolean) {
    this.showLoginModal = show
  }

  // // @todo: Rework it: move the modal state to AppStore instead.
  // public setModalCloseHandler(modalCloseHandler: Function) {
  //   this.modalCloseHandler = modalCloseHandler
  // }
  // public callModalCloseHandler() {
  //   if (this.modalCloseHandler == null) {
  //     return
  //   }
  //   this.modalCloseHandler()
  // }

  public async uploadModel(data: FormData) {
    try {
      await postModel(data)
      return true
    } catch (e: any) {
      this.handleError(e)
      return false
    }
  }

  protected handleError(e: any | null) {
    if (e !== null) {
      if (e.name === 'CanceledError') {
        error('Request cancelled by user')

        return
      }

      if (e.response?.data?.instruction == 'login')
        window.open(LOGIN_URL, '_blank')
      else {
        if (e.response?.data?.display) {
          error(e.response?.data?.display)
        } else if (e.response?.data?.message) {
          error(e.response?.data?.message)
        } else {
          error(e.message)
        }
      }
      return
    }
    error('An error has occurred. Please let the app developers know.')
  }

  protected handleSuccess(message: string) {
    success(message)
  }
}
