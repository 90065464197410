// import { Box } from "@mui/material"
import { IDashboardItem } from "../../core/types/code_service/IDashboardItem"
// import AnswerSection from "../AskCubie/AnswerSection"
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
// import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import { makeStyles } from "../../core/utils/theme"
import { FC } from "react"

const useStyles = makeStyles()(() => ({
  slideOverlayContainer: {
    height: '100%',

  },
  slideOverlayScreen: {
    position: 'absolute',
    top:'0',
    bottom:'0',
    right:'0',
    left:'0',
    background:'white',
    zIndex:'5',
    alignItems:'center',
    backdropFilter: 'blur(12px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignContent:'center'
  },
  slideContainer: {
    width:'90%',
    maxWidth:'1400px',
    aspectRatio:'16/10',
    margin: 'auto auto',
    background:'#def',
    border: '1px solid black',
    position:'relative',
    display:'flex',
    alignContent:'center',
  },
  slideAnswerContainer: {
    width: '90%',
    maxWidth:'1200px',
    aspectRatio: '16/10',
    margin: 'auto auto',

    background: '#def',
    border: '1px solid black',
  }
}))
interface ISlideOverlay {
  overlayItems: IDashboardItem[] | null
  currentItemIndex: number
  closeOverlay: ()=>void
  setCurrentItemIndex: (i:number)=>void
}
  
const SlideOverlay: FC<ISlideOverlay> = ({overlayItems, currentItemIndex, closeOverlay, setCurrentItemIndex}) => {
  const { classes } = useStyles()
  const dbItem: IDashboardItem | null = overlayItems ? overlayItems[currentItemIndex] : null
  if (!dbItem)
    return null

  closeOverlay()
  setCurrentItemIndex(5)
  classes

  // const nextIndex:number = overlayItems ? (currentItemIndex + 1) % overlayItems.length : 0
  // const prevIndex:number = overlayItems ? (currentItemIndex - 1 + overlayItems.length) % overlayItems.length : 0

  return (<></>
    // <Box className={classes.slideOverlayScreen}>
    //   <Box className={classes.slideContainer}>
    //     <Box className="absolute top-0 w-full flex justify-center m-4">{dbItem?.answer?.title}</Box>
    //     <CloseFullscreenIcon className="absolute top-2 right-2 cursor-pointer z-[2]" onClick={() => closeOverlay()} />
    //     {overlayItems && overlayItems.length > 1 && (
    //       <ArrowBackIosIcon className='absolute left-2 top-[48%] cursor-pointer' onClick={()=>setCurrentItemIndex(prevIndex)} />
    //     )}
    //     {overlayItems && overlayItems.length > 1 && (
    //       <ArrowForwardIosIcon className='absolute right-2 top-[48%] cursor-pointer' onClick={()=>setCurrentItemIndex(nextIndex)} />
    //     )}
    //     <Box className={classes.slideAnswerContainer}>
    //       <AnswerSection
    //           answer={dbItem.answer} 
    //           isDashboard={true}/>

    //       {/* {dbItem.answer.chart_html && <HTMLChart answerChartHtml={dbItem.answer.chart_html} isDashboard={true} />} */}
    //     </Box>
    //   </Box>
    // </Box>
  )
}

export default SlideOverlay
  