import { Box, Button } from '@mui/material'
import { observer } from 'mobx-react'
import { FC } from 'react'
// import { thoughtsProfile } from '../../core/config/dompurify'
import { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
// import { to_html } from '../../core/utils/main'
import { LoadingSpinner } from '../Misc/Loader'
// import NewWindow from 'react-new-window'
import loadinggif from '../../assets/loading.gif'
import EditableLine from '../Misc/EditableLine'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    height:'100%',
    width: '100%',
    marginBottom: '0rem',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.25rem',
    background: '#88888833',
    '& > div': {
      padding: '0.25rem',
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  },
  thoughts: {
    fontSize: '12px',
    width: '100%',
    maxWidth:'100%',
    minHeight: '4rem',
    maxHeight: '100%',
    overflowX: 'clip',
    overflowY: 'auto',
    padding: '0 0.75rem 0.75rem',
    '& pre': {
      whiteSpace: 'pre-wrap'
    }
  },
  thinkingLoader: {
    margin: '8px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: theme.palette.text.primary,
  },
  thinkingLoaderSpinner: {
    display: 'block',
    width: '10rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}))

export const Thoughts: FC = observer(() => {
  const { classes, cx } = useStyles()
  const app = useApp()

  return (
    <Box className={cx(classes.root, 'action-log')}>
      <Box className={classes.title}>
        <Box>Cubie's Sketchpad {app.isThinking && <LoadingSpinner className="inline h-[20px] ml-[10px]"/>}</Box>
      </Box>
      <Box className={classes.thoughts}>
        {Object.keys(app.queryState.sources).length > 0 && 
          <>
            <br/>
            <b>Data Sources</b>
                {Object.keys(app.queryState.sources).map((k)=> (
                  <div key={k}>
                    <b>{k}</b>: {app.queryState.sources[k as keyof object]}
                  </div>
                ))}
            <br/>
          </>
        }
        {app.queryState.assumptions.length > 0 && 
          <>
            <br/>
            <b>Assumptions</b>
            {app.queryState.assumptions.map((a:string, i: number) => (
              <Box>
                {/* <span className="cursor-pointer inline" onClick={()=>app.queryState.assumptions.splice(i,i)}>-</span> */}
                <EditableLine key={i} line={a} onFinish={(l: string)=>{app.queryState.assumptions[i]=l}} />
              </Box>
            ))}
            <Box className="cursor-pointer" onClick={()=>{app.queryState.assumptions.push("* ...")}}>+</Box>
            {app.queryState.state == 'assumptions' && <Button onClick={()=>app.ask()}>Approve?</Button>}
          </>
        }
        {app.queryState.plan.length > 0 &&
          <>
            <b>Plan</b><span className="ml-2 opacity-40 hover:opacity-100 cursor-pointer" onClick={()=>app.queryState.plan=[]}>x</span>
            {app.queryState.plan.map((a:string, i: number)=> (
              <Box>
                {/* <span className="cursor-pointer inline" onClick={()=>app.queryState.plan.splice(i,i)}>-</span> */}
                <EditableLine key={i} line={a} onFinish={(l: string)=>{app.queryState.plan[i]=l}} />
              </Box>
            ))}
            <Box className="cursor-pointer" onClick={()=>{app.queryState.plan.push("...")}}>+</Box>
            {app.queryState.state == 'plan' && <Button onClick={()=>app.ask()}>Approve?</Button>}
          </>
        }
        {app.queryState.code.length > 0 &&
          <>
            <br/>
            <b>Code</b> <span className="ml-2 opacity-40 hover:opacity-100 cursor-pointer" onClick={()=>app.queryState.code=[]}>x</span>
            {app.queryState.code.map((a:string, i: number)=> <EditableLine key={i} line={a} onFinish={(l: string)=>{app.queryState.code[i]=l}} />)}
          </>
        }
        {app.isThinking && <Box className={classes.thinkingLoader}><img src={loadinggif} style={{height:'50px'}}/></Box>}
      </Box>
    </Box>
)})


